import {Box, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import React from "react";
import {AnswerDto, ScoreData} from "../lib/types";
import {getFilesUrl} from "../../../shared";
import {CrossIconButton} from "../../cross-icon-button";
import {AssessmentMark} from "./AssessmentMark";
import {readableCategory} from "../lib/utils";

export function VideoWithTranscriptDialog(props: { data?: AnswerDto, onClose: () => void, scoring?: ScoreData[]}) {

  const transcript: string = props?.data?.videoAnswer?.transcriptJson ? JSON.parse(props!!.data!!.videoAnswer!!.transcriptJson).results.transcripts[0].transcript : "N/A"
  const filteredScoring = props.scoring?.filter(it => it.answerId === props.data?.videoAnswer?.id)

  return <Dialog onClose={props.onClose}
                 scroll={"paper"}
                 open={!!props.data}
                 hideBackdrop={false}
                 PaperProps={{elevation: 1}}
                 fullWidth={true}>


    <DialogTitle>
      <Stack justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
        <Box>
          <Typography sx={{fontSize: "16px"}}>{props.data?.title}</Typography>
        </Box>
        <CrossIconButton onClose={props.onClose}/>
      </Stack>
    </DialogTitle>
    <DialogContent sx={{width: '100%', height: '800px'}}>
        <Stack spacing={2} direction={"column"}>

          <Box component={'video'}
               controls sx={{width: 1, borderRadius: '4px'}}
               autoPlay={false}
               src={getFilesUrl(props.data?.videoAnswer?.fileStorage) + "/" + props.data?.videoAnswer?.url}
               style={{display: 'block'}}/>

          <Box>
            <Typography>Answer id</Typography>
            <Typography sx={{fontSize: "12px"}}>{props.data?.videoAnswer?.id}</Typography>
          </Box>

          <Box>
            <Typography>Question id</Typography>
            <Typography sx={{fontSize: "12px"}}>{props.data?.questionId}</Typography>
          </Box>

          <Box>
            <Typography variant={"body1"} sx={{fontWeight: "bold"}}>Transcript</Typography>
            <Typography variant={"body1"}>{transcript}</Typography>
          </Box>
          <Box>
            {filteredScoring?.map((item, i) => (
              <Stack key={i} direction={"column"} spacing={2} sx={{paddingTop: 3}}>
                <Stack direction={"row"} spacing={3} justifyContent={"space-between"}>
                  <Box>{readableCategory(item.category)}</Box>
                  <Box><AssessmentMark value={item.score} /></Box>
                </Stack>
                <Box>{item.reasoning}</Box>
              </Stack>
            ))}
          </Box>
        </Stack>
    </DialogContent>
  </Dialog>
}
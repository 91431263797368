import React, {FC, ReactNode, Suspense} from "react";
import {Box, Container, Skeleton, Stack, Typography} from "@mui/material";
import {useIsMobile} from "./useIsMobile";

export function withSuspense<P>(
  WrappedComponent: FC<P>,
  FallbackComponent: ReactNode = <DefaultSkeleton/>
) {
  return function SuspenseWrapper(props: P) {
    return (
      <Suspense fallback={FallbackComponent}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };
}

export function DefaultSkeleton() {
  const isMobile = useIsMobile()

  return <Container maxWidth={"md"}  sx={{height: "100vh", alignItems: "center", justifyContent: "center", display: "flex", flexDirection:"column", overflowY: 'none', marginTop: "100px" }}>
    <Stack direction={{xs: "column", sm: "row"}} spacing={2} sx={{width: 1}}>
      <Stack spacing={2} sx={{width: 1}}>
        <Skeleton variant="text"/>
        <Skeleton variant="rounded" height={100}/>
        <Skeleton variant="rounded" height={160}/>
        <Skeleton variant="rounded" height={160}/>
      </Stack>
      {!isMobile &&
        <Stack spacing={2} sx={{width: 1}}>
          <Skeleton variant="rounded" height={100}/>
          <Skeleton variant="rounded" height={160}/>
          <Skeleton variant="text"/>
          <Skeleton variant="rounded" height={160}/>
        </Stack>
      }
    </Stack>
    <Box sx={{height: '200px'}} />
  </Container>
}
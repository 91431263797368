import {Card, Stack, Typography} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {MigratePositionMatchButton, MigrateStripeAccounts, MigrateTypeformAnswers} from "../../../feature/service";
import {GoogleMeetButton} from "../../../feature/service/google-meet-button";
import {MigrateResume} from "../../../feature/service/migrate-resume";
import {MigrateAttributesButton} from "../../../feature/service/migrate-attributes-button";

const Page = () => {

  return <div>
    <Card sx={{m: 3, p: 3}}>
      <Stack spacing={2} sx={{width: '25vw'}}>

        <Typography sx={{fontWeight: 700, fontSize: 18}}>Service page</Typography>
        <MigrateStripeAccounts />
        <MigratePositionMatchButton />
        <GoogleMeetButton />
        <Link to={"/admin/service/scheduled-emails"}>Last emails</Link>
        <MigrateResume />
        <MigrateAttributesButton />
        <MigrateTypeformAnswers />
      </Stack>

    </Card>
  </div>
}

export default Page

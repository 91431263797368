import {useNavigate} from "react-router-dom";
import {useSalesAssessmentByContractorSuspenseQuery} from "../../api/sdl";
import {Button, Stack} from "@mui/material";

interface Props {
  contractorId: string
}

export function AdminSalesAssessmentCard({contractorId}: Props) {

  const navigate = useNavigate()
  const {data} = useSalesAssessmentByContractorSuspenseQuery({variables: {contractorId}})

  const values = data?.salesAssessmentByContractor?.assessmentCollection?.map(it => it.score)
  const average = values ? Math.round(10 * values?.reduce((sum, num) => sum + num, 0) / values?.length): "N/A"

  return <>
    <Stack spacing={2}>
      <Stack direction={"row"}>
        <Button onClick={async () => {
          navigate(`/admin/contractor/sales-assessment/${contractorId}`)
        }}>Assessment ({average})</Button>
      </Stack>
    </Stack>
  </>
}


import {Navigate, Outlet} from 'react-router-dom'
import React, {useContext} from "react";
import {KeycloakContext, keycloakLoginUrl} from "../../keycloak";
import {ProfileContextProvider} from "../../shared/ProfileContext";
import {useLocation} from "react-router-dom";

export function PrivateRouteUpdated() {
  const {initialized, authenticated} = useContext(KeycloakContext);
  const location = useLocation()
  if (initialized) {
    if (authenticated) {
      return <ProfileContextProvider>
        <Outlet/>
      </ProfileContextProvider>
    } else {
      if(location.pathname === "/contractor" || location.pathname.startsWith("/contractor?")) {

        return <Navigate to={"/"} replace={true} />

      } else {

        const url = keycloakLoginUrl()
        window.location.href = url
      }
    }
  }

  return null
}


import {Box, Button, Chip, Stack, Typography} from "@mui/material";
import {CompositeAnswerApiData} from "./lib/types";
import React, {useEffect, useMemo, useState} from "react";
import {AnswerType} from "../../../../api/sdl";
import {VideoAnswer} from "./ui/VideoAnswer";
import {TextAnswer} from "./ui/TextAnswer";
import VideoRecordModal from "../../../video-record-modal/VideoRecordModal";
import {Editable} from "../../../../shared";
import {TextAnswerModal} from "./ui/TextAnswerModal";

interface Props extends Editable {
  answers: CompositeAnswerApiData[],
  onUpdate: () => void,
  contractorId: string,
}

export function CombinedAnswers(props: Props) {

  const [short, setShort] = useState(true)
  const [recordOpen, setRecordOpen] = useState(false);
  const [recordingTitle, setRecordingTitle] = useState<string>("")
  const [recordingId, setRecordingId] = useState<string>()

  const [textOpen, setTextOpen] = useState(false)
  const [modalAnswerValue, setModalAnswerValue] = useState<string>("")

  const readinessPercent = useMemo(() => {
    return Math.round(
      100 * (props.answers.map(it => it.textAnswer != null || it.videoAnswer != null).filter(it => it == true).length / props.answers.length)
    )
  }, [props.answers])

  const displayed = useMemo(() => {

    if(props.editable) {
      // Always show full list on edit
      return props.answers
    }
    else {
      if(short) {
        return props.answers.filter(a => !!a.videoAnswer || !!a.textAnswer).slice(0, 10)
      } else {
        return props.answers.filter(a => !!a.videoAnswer || !!a.textAnswer)
      }
    }
  }, [short, props.answers]);

  const handleRecordClick = (item: CompositeAnswerApiData) => {
    setRecordOpen(true)
    setRecordingTitle(item.title)
    setRecordingId(item!.questionId)
  }

  const refresh = () => {
    setRecordOpen(false)
    setRecordingId(undefined)
    setRecordingTitle("")
    props.onUpdate()
  }

  const handleTextEdickClick = (item: CompositeAnswerApiData) => {

    setTextOpen(true)
    setRecordingTitle(item.title)
    setRecordingId(item!.questionId)
    setModalAnswerValue(item?.textAnswer?.answer || "")
  }
  return <>
    {props.editable && recordingId && textOpen &&
      <TextAnswerModal
        contractorId={props.contractorId}
        title={recordingTitle}
        questionId={recordingId}
        open={textOpen}
        answer={modalAnswerValue}
        onUpdate={refresh}
        onClose={() => {
          setTextOpen(false)
        }}
      />
    }
    {props.editable &&
      <VideoRecordModal open={recordOpen}
                      title={recordingTitle}
                      onRecorded={refresh}
                      contractorId={props.contractorId}
                      videoQuestionId={recordingId}
                      onClose={() => {
                        setRecordOpen(false);
                      }} />
    }
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"baseline"}>
      <Typography variant={"h3"}>Sales Assessment</Typography>
      {props.editable &&
        <CompletionProcessBar percent={readinessPercent}/>
      }
    </Stack>
    {props.editable &&
        <Stack spacing={2}>
            <Typography variant={"body1"}>
                Complete your sales assessment to be accepted on the MDliaison platform.
                These answers will help us understand how you sell and whether or not you'll be a good fit to work with our customers.
            </Typography>
            <Typography variant={"body1"}>
                <strong>You must complete all of the sales assessment questions in one session.</strong> Please dedicate
                30-60 minutes to be able to complete the full sales assessment.
                Each answer should be concise and you should spend <strong>no longer than 3 minutes per answer</strong>.
            </Typography>
        </Stack>
    }
    <Stack spacing={0}>
      {displayed.map(answer => (
        answer.type === AnswerType.Text
          ? <TextAnswer item={answer} key={answer.questionId} editable={props.editable} onEdit={() => handleTextEdickClick(answer)}/>
          : <VideoAnswer item={answer} key={answer.questionId} editable={props.editable} onRecordClick={() => {handleRecordClick(answer)}}/>
      ))}

    </Stack>
    {!props.editable &&
      <Box sx={{display: "flex", justifyContent: "center"}}>
       <Button onClick={() => setShort(!short)}>{short?"see more answers":"see less answers"}</Button>
      </Box>
    }
  </>
}

function CompletionProcessBar({percent}: {percent: number}) {
  return <Chip size={'small'}
               sx={{
                 // @ts-ignore
                 background: '#FFF',
                 // @ts-ignore
                 color: '#5D5E5F',
                 // @ts-ignore
                 border: '1px solid #B0B3B2',
                 mr: 1,
                 mb: 1
               }}
               label={`Completed - ${percent}%`}
                />
}
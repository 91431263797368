import React, {useState} from 'react';
import Keycloak, {KeycloakInitOptions} from 'keycloak-js'
import config from "./config";
import {DefaultSkeleton} from "./shared/hooks/withSuspense";

export const keycloakConfig = {
  realm: config.realm,
  url: config.keycloakUrl,
  clientId: 'frontend'
}

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// @ts-ignore
export const keycloak: Keycloak.KeycloakInstance = new Keycloak(keycloakConfig);

export enum KeycloakRoles {
  admin = 'Admin',
  company = 'Company',
  contractor = 'Contractor'
}

export function useUserId(): string | undefined {
  return keycloak?.tokenParsed?.sub;
}

interface KeycloakContextProps {
  keycloak: Keycloak.KeycloakInstance,
  initialized: boolean,
  authenticated: boolean | null,
}

export const KeycloakContext = React.createContext<KeycloakContextProps>({
  keycloak,
  initialized: false,
  authenticated: null,
});

export function KeycloakProvider(props: React.PropsWithChildren<any>) {
  const [initialized, setInitialized] = useState(false);
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);

  React.useEffect(() => {
    return window.addEventListener("online", () => {
      console.log("You've come back online.");

      try {
        keycloak.updateToken(10)
      } catch (e) {
        console.error(e)
      }

    });
  }, []);


  React.useEffect(() => {
    // debugger;
    if (keycloak) {
      if (!config.isProd && !(window as any).keycloak) {
        (window as any).keycloak = keycloak;
      }
      if (!initialized) {
        const initOptions: KeycloakInitOptions = {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          scope: "openid roles",
          silentCheckSsoRedirectUri: window.location.origin,
          enableLogging: false,

        };

        const tokens = sessionStorage.getItem('token');

        if (tokens) {
          let {accessToken: token, refreshToken, idToken} = JSON.parse(tokens);
          Object.assign(initOptions, {token, refreshToken, idToken})
        }

        keycloak.init(initOptions).then((authenticated: boolean) => {
          setInitialized(true);
          setAuthenticated(!!authenticated)
        })

        keycloak.onAuthRefreshError = () => {
          console.error('onAuthRefreshError');
          // it made infinite reload.
          // keycloak.logout();
        };

        keycloak.onTokenExpired = () => {

          console.info('onTokenExpired');
          if(navigator.onLine) {
            keycloak.updateToken(10)
          } else {
            console.error("it's offline, can't refresh the token")
          }
        };

      }
    } else {
      console.error("keycloak doesn't exist in global scope")
    }
  }, [initialized])


  if (!initialized) {
    return <DefaultSkeleton />
  }

  return  <KeycloakContext.Provider value={{initialized, keycloak, authenticated}}>
    {props.children}
  </KeycloakContext.Provider>
}

export const withKeycloakContext = (WrappedComponent: any) => (props: any) => {
  return (
    <KeycloakProvider>
      <WrappedComponent {...props} />
    </KeycloakProvider>
  )
}

export const keycloakLoginUrl = () => keycloak.createLoginUrl({redirectUri: document.location.href, scope: "openid roles"})

import {Typography} from "@mui/material";
import React from "react";

export function AssessmentMark({value}:{value: number}) {
  let label = "";
  let color = "";
  let fontWeight: number | undefined = undefined;

  if (value >= 1.5) {
    label = "Strong";
    color = "#03a03c";
    fontWeight = 600;
  } else if (value >= 0.5) {
    label = "Some";
    color = "#f99809";
    fontWeight = 600;
  } else {
    label = "None";
    color = "#8c8c8c";
  }

  return (
    <Typography sx={{ color }} fontWeight={fontWeight}>
      {label}
    </Typography>
  );
}
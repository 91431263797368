import React, {useState} from "react";
import {Box, Grid, Paper, Dialog, DialogTitle, Card, Typography, Popover, Button} from "@mui/material";
import {ScoreData} from "../lib/types";
import { readableCategory } from "../lib/utils";
import {AssessmentMark} from "./AssessmentMark";


interface ScoreGridProps {
  data: ScoreData[];
}

const ScoreGrid: React.FC<ScoreGridProps> = ({data}) => {

  const [explanation, setExplanation] = useState("")
  const [openDialog, setOpenDialog] = useState(false)
  const [averages, setAverages] = useState<any>({})
  const [popoverQuestion, setPopoverQuestion] = useState<string>("")

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);


  // Extract unique categories for columns
  const categories: string[] = [...new Set(data.map((item) => item.category))];

  // Extract unique answerIds for rows
  const answerIds: string[] = [...new Set(data.map((item) => item.answerId))];

  // Create a lookup object for scores
  const scoreLookup: Record<string, Record<string, number>> = {};
  data.forEach((item) => {
    if (!scoreLookup[item.answerId]) {
      scoreLookup[item.answerId] = {};
    }
    scoreLookup[item.answerId][item.category] = item.score;
  });

  React.useEffect(() => {

    setAverages(calculateAverageByCategory(data))
  }, [data])

  const handleClickOnScore = (answerId: string, category: string) => {
    setExplanation(`${data.find((it) => it.answerId === answerId && it.category === category)?.reasoning}`)
    setOpenDialog(true)
  }

  const readableQuestion = (data: ScoreData[], answerId: string) => {
    return data.find(d => d.answerId === answerId)?.questionTitle || "N/A"
  }


  const handleQuestionClick = (event: any, questionText: string) => {
    setPopoverQuestion(questionText)
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = !!anchorEl;
  const id = openPopover ? 'simple-popover' : undefined;


  return <>
    <Popover
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Typography sx={{p: 2}}>{popoverQuestion}</Typography>
    </Popover>
    <SimpleDialog explanation={explanation} onClose={() => setOpenDialog(false)} open={openDialog}/>
    <Typography variant={"h3"}>Correlation matrix</Typography>

    <Card sx={{overflow: 'scroll'}}>
      <Box sx={{padding: 2, paddingTop: "60px"}}>

        <Grid
          container
          spacing={1}
          sx={{
            display: "grid",
            gridTemplateColumns: `150px repeat(${categories.length}, 1fr)`,
            gap: "5px",
            alignItems: "center",
          }}
        >
          {/* Header Row */}
          <Paper elevation={0} sx={{padding: 1, textAlign: "center", fontWeight: "bold"}}> - </Paper>
          {categories.map((category) =>
            <Paper key={category}
                   elevation={0}
                   sx={{
                     padding: 1,
                     textAlign: "left",
                     transform: "rotate(-30deg)",
                     transformOrigin: "left bottom",
                   }}>
              {readableCategory(category)}
            </Paper>
          )}

          {/* Data Rows */}
          {answerIds.map((answerId) =>
            <React.Fragment key={answerId}>

              <Paper elevation={0}
                     onClick={(e) => handleQuestionClick(e, readableQuestion(data, answerId))}
                     sx={{
                       padding: 1,
                       width: "150px",
                       overflow: "hidden",
                       height: "50px"
                     }}>{readableQuestion(data, answerId)}</Paper>
              {categories.map((category) => (
                <Paper key={`${answerId}-${category}`}
                       elevation={scoreLookup[answerId][category] ? 0 : 0}
                       sx={{padding: 1, textAlign: "center", cursor: "pointer"}}
                       onClick={() => handleClickOnScore(answerId, category)}>
                  {scoreLookup[answerId][category] !== undefined ?
                    <AssessmentMark value={scoreLookup[answerId][category]}></AssessmentMark>
                    : "-"}
                </Paper>
              ))}
            </React.Fragment>
          )}
          <Box></Box>
          {categories.map((category) => (
            <Box sx={{padding: 1, textAlign: "center",}} key={category}>
              <AssessmentMark value={averages[category]} />
            </Box>
          ))}
        </Grid>
      </Box>
    </Card>
  </>
};

export default ScoreGrid;


export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  explanation: string;
}

function SimpleDialog(props: SimpleDialogProps) {
  const {onClose, open, explanation} = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} hideBackdrop={false} PaperProps={{elevation: 1}}>
      <DialogTitle>Score explanation</DialogTitle>
      <Box sx={{m: 3}}>{explanation}</Box>
    </Dialog>
  );
}

function calculateAverageByCategory(data: ScoreData[]): Record<string, number> {
  const categorySums = data.reduce<{ [key: string]: { total: number; count: number } }>((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = {total: 0, count: 0};
    }

    if (curr.score != 0) {
      acc[curr.category].total += curr.score;
      acc[curr.category].count += 1;
    }

    return acc;
  }, {});

  const categoryAverages: Record<string, number> = {};
  for (const category in categorySums) {
    categoryAverages[category] = Math.round(10 * categorySums[category].total / categorySums[category].count) / 10;
  }

  return categoryAverages;
}

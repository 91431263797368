import React, {useState} from "react";
import {Box, Grid, Paper, Dialog, DialogTitle, Button, Card, CardContent, Typography, Stack} from "@mui/material";
import {AnswerType, SalesAssessmentByContractorQuery, useProcessInterviewAnswerMutation} from "../../../api/sdl";
import {AnswerDto, ScoreData} from "../lib/types";
import {VideoWithTranscriptDialog} from "./VideoWithTranscriptDialog";



interface ScoreGridProps {
  answers: AnswerDto[];
  scoring?: ScoreData[];
}

const TranscribedAnswerList: React.FC<ScoreGridProps> = ({ answers, scoring }) => {

  const [currentAnswer, setCurrentAnswer] = useState<AnswerDto | undefined>()

  const videoAnswers = answers.filter(it=> it.type === AnswerType.Video )

  // processInterviewAnswer
  const [requestAnswerUpdate] = useProcessInterviewAnswerMutation()

  const handleUpdate = async (answerId: string) => {
    await requestAnswerUpdate({variables: {interviewAnswerId: answerId}})
  }

  const handleIconClick = (answer: AnswerDto) => {
    setCurrentAnswer(answer)
    console.log(scoring)
  }

  return <>
    <VideoWithTranscriptDialog data={currentAnswer} onClose={() => setCurrentAnswer(undefined)} scoring={scoring} />
    <Typography variant={"h3"}>Video answers without transcript</Typography>
    <Box sx={{ padding: 1, marginTop: 2 }}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "grid",
          gridTemplateColumns: `150px repeat(0, 1fr)`,
          gap: "5px",
          alignItems: "center",
        }}
      >
        {videoAnswers.map( answer => <Card key={answer.questionId}>
            <CardContent>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Box>{answer.title}</Box>
                <Box><Button variant={"contained"} onClick={() => handleUpdate(answer.videoAnswer?.id)}>Update</Button></Box>
              </Stack>

              <Stack direction={"row"} spacing={2}>

                <Box component={'img'} src={`/img/teenyicons/play-circle.svg`} width={22} height={22}
                     onClick={() => handleIconClick(answer)}
                     sx={{margin: 1, marginTop: '4px', cursor: 'pointer'}}/>


                <Box>{answer.videoAnswer?.fileStorage}</Box>
                <Box>{answer.videoAnswer?.url}</Box>
                <Box>
                  <Typography sx={{color: answer.videoAnswer?.transcriptJson ? "#000": "#F00"}}>{answer.videoAnswer?.transcriptJson ? "Transcript exists": "No transcript"}</Typography>
                </Box>
              </Stack>
              {!answer.videoAnswer &&
                  <Box>
                      <Typography variant={"body1"} sx={{color: "#F00"}}>No interview recorded</Typography>
                  </Box>
              }
            </CardContent>
          </Card>
        )}
      </Grid>
    </Box>
  </>

};

export default TranscribedAnswerList;


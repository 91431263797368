import {createBrowserRouter, Navigate} from "react-router-dom";
import MeetingCardPage from "./shared/pages/MeetingCardPage";
import RegisterCompany from "./company/pages/RegisterCompany";
import {NotFound} from "./feature/not-found/NotFound";
import React from "react";
import {StartRedirect} from "./feature/start-redirect";
import {LayoutWithMenu} from "./layout";
import CompanyContracts from "./company/pages/CompanyContracts";
import {HirePage} from "./domain/hiring";
import CompanyMeetings from "./company/pages/company-meetings";
import Notifications from "./feature/notifications";
import ThankYou from "./company/pages/ThankYou";
import HiringComplete from "./company/pages/HiringComplete";
import {ErrorBoundary} from "./feature/error-boundary/ErrorBoundary";
import {BalanceReportsPage} from "./feature/balance-reports";
import {GoogleWorkspaceCodeReader} from "./feature/google-workspace-code-reader";
import {PositionListActive} from "./feature/position-list-active/PositionListActive";
import {LastScheduledEmails} from "./feature/service";
import {ContractorsPage} from "./admin/pages/contractors/ContractorsPage";
import RegisterContractor from "./contractor/pages/onboarding/RegisterContractor";
import {AppRoot} from "./layout/AppRoot";
import {PrivateRouteUpdated} from "./domain/routing/PrivateRouteUpdated";
import {PublicRoute} from "./domain/routing/PublicRoute";
import {ContractorRoute} from "./domain/routing/ContractorRoute";

import {ContractorAdminDetails} from "./admin/pages/contractors/ContractorAdminDetails";
import {PositionCreationPage} from "./domain/matching_process/page/PositionCreationPage";
import {unifiedMenu} from "./layout/menu/unifiedMenu";
import { CandidateCarouselLayout } from "./feature/candidate-carousel-layout/CandidateCarouselLayout";
import {CandidateCardPreview} from "./feature/candidate-card-preview/CandidateCardPreview";
import {Box} from "@mui/material";
import {CandidateCardSchedule} from "./feature/candidate-card-schedule/CandidateCardSchedule";
import {CandidateCardReschedule} from "./feature/candidate-card-reschedule/CandidateCardReschedule";

import PositionsLazy from "./company/pages/positions-page/Positions";
import EmailForm from "./admin/pages/emails/EmailForm";
import EmailList from "./admin/pages/emails/EmailList";
import AdminContract from "./admin/pages/contracts/AdminContract";
import AdminContractList from "./admin/pages/contracts/AdminContractList";
import CompanyList from "./admin/pages/companies/CompanyList";
import InvoiceList from "./admin/pages/invoices/InvoiceList";
import PositionListByCompany from "./feature/position-list-by-company/PositionListByCompany";
import ExternalContractorList from "./admin/pages/external/ExternalContractorList";
import AttributeForm from "./admin/pages/attributes/AttributeForm";
import AttributeList from "./admin/pages/attributes/AttributeList";
import CreateContractor from "./admin/pages/contractors/CreateContractor";
import Manager from  "./admin/pages/companies/Manager";
import ServicePage from  "./admin/pages/service/ServicePage";
import AdminPositionPage from  "./domain/matching_process/page/AdminPositionPage";
import PositionPage from  "./domain/matching_process/page/position-page/PositionPage";
import ContractorPositions from "./contractor/pages/ContractorPositions";
import ProfessionalProfile from "./contractor/pages/ProfessionalProfile";
import Settings from "./company/pages/Settings";
import CalendarPage from "./contractor/pages/CalendarPage";
import PersonalInfo from "./contractor/pages/onboarding/PersonalInfo";
import OnboardingCompleted from "./contractor/pages/onboarding/OnboardingCompleted";
import ReschedulePage from "./domain/matching_process/page/ReschedulePage";
import ContractorPayouts from "./contractor/pages/contractor-payouts/ContractorPayouts";
import ContractorPositionDetailsPage from "./domain/matching_process/page/ContractorPositionDetailsPage";
import { AdminSalesAssessmentReport } from "./feature/admin-sales-assessment-report/AdminSalesAssessmentReport";

export const routerRules = createBrowserRouter([

  {
    path: "/",
    element: <AppRoot/>,
    errorElement: <ErrorBoundary/>,
    children: [
      {
        path: "/start",
        element: <StartRedirect/>
      },
      {
        path: "/interview-meeting-v2/:meetingId",
        element: <MeetingCardPage/>,
      },
      // {
      //   path: "/contractor-onboarding/personal-info",
      //   element: <PersonalInfo />
      // },
      {
        element: <PublicRoute/>,
        children: [

          {
            path: "/interview-meeting/:meetingId",
            element: <MeetingCardPage/>,
          },
          {
            path: "/company-onboarding",
            element: <RegisterCompany/>
          },
          {
            path: "/contractor-onboarding",
            element: <RegisterContractor/>
          }

        ]
      },
      {
        element: <PrivateRouteUpdated/>,
        children: [
          {
            path: "/admin",
            element: <LayoutWithMenu menu={unifiedMenu} showMenu/>,
            children: [
              {
                errorElement: <ErrorBoundary/>,
                children: [
                  //           <PrivateRoute exact path={'/reschedule/:id'} component={ReschedulePage} />
                  {
                    path: "contractor/sales-assessment/:contractorId",
                    element: <AdminSalesAssessmentReport />
                  },
                  {
                    path: "reschedule/:positionMatchId/meeting/:meetingId",
                    element: <ReschedulePage />
                  },
                  {
                    path: "google-workspace",
                    element: <GoogleWorkspaceCodeReader/>,
                  },
                  {
                    path: "contracts",
                    element: <AdminContractList/>,
                  },
                  {
                    path: "contract/:id",
                    element: <AdminContract/>,
                  },
                  {
                    path: "companies",
                    element: <CompanyList/>
                  },
                  {
                    path: "invoices",
                    element: <InvoiceList/>
                  },
                  {
                    path: "company/:id",
                    element: <PositionListByCompany/>
                  },
                  {
                    path: "company/:id/manager",
                    element: <Manager/>
                  },
                  {
                    path: "company/:companyId/position/:positionId?/:contractorId?",
                    element: <AdminPositionPage/>
                  },
                  {
                    path: "emails",
                    element: <EmailList/>
                  },
                  {
                    path: "email/:id?",
                    element: <EmailForm/>
                  },
                  {
                    path: "meetings",
                    element: <CompanyMeetings/>
                  },
                  {
                    path: "contractors",
                    element: <ContractorsPage/>
                  },
                  {
                    path: "contractor/:id",
                    element: <ContractorAdminDetails/>
                  },
                  {
                    path: "contractor",
                    element: <CreateContractor/>
                  },
                  {
                    path: "attributes",
                    element: <AttributeList/>
                  },
                  {
                    path: "attribute/:id?",
                    element: <AttributeForm/>
                  },
                  {
                    path: "notifications",
                    element: <Notifications/>
                  },
                  {
                    path: "external",
                    element: <ExternalContractorList/>
                  },
                  {
                    path: "service",
                    element: <ServicePage/>
                  },
                  {
                    path: "service/scheduled-emails",
                    element: <LastScheduledEmails/>
                  },
                  {
                    path: "positions",
                    element: <PositionListActive/>
                  }
                ]
              }
            ]
          },
          {
            path: "/contractor",
            element: <ContractorRoute/>,
            children: [
              {
                errorElement: <ErrorBoundary/>,
                children: [
                  { index: true, element: <Navigate to={"/contractor/profile"} /> },
                  {
                    path: "personal-info",
                    element: <PersonalInfo />
                  },
                  {
                    path: "onboarding-completed",
                    element: <OnboardingCompleted />
                  },
                  {
                    path: "positions/:positionId",
                    element: <ContractorPositionDetailsPage/>
                  },
                  {
                    path: "position/:positionId",
                    element: <ContractorPositionDetailsPage/>
                  },
                  {
                    path: "positions",
                    element: <ContractorPositions/>
                  },
                  {
                    path: "notifications",
                    element: <Notifications/>
                  },
                  {
                    path: "profile",
                    element: <ProfessionalProfile/>
                  },
                  {
                    path: "payouts",
                    element: <ContractorPayouts/>
                  },
                  {
                    path: "settings",
                    element: <Settings/>
                  },
                  {
                    path: "calendar",
                    element: <CalendarPage/>
                  },
                  {
                    path: "schedule",
                    element: <CalendarPage/>,
                  },
                  // {
                  //   path: "video",
                  //   element: <VideoPage/>
                  // },
                  {
                    path: "balance-reports",
                    element: <BalanceReportsPage/>
                  }
                  //       <PrivateRoute path={'/schedule'} element={() => withLayout(CalendarPage, menu)} />
                  //       <PrivateRoute path={'/video'} element={<VideoPage />} />
                  //       <PrivateRoute path={'/balance-reports'} element={() => withLayout(BalanceReportsPage, menu)} />
                ]
              }
            ]
          },
          {
            path: "/company",
            element: <LayoutWithMenu menu={unifiedMenu} showMenu />,
            children: [
              {
                errorElement: <ErrorBoundary/>,
                children: [
                  {
                    path: "positions",
                    element: <PositionsLazy/>
                  },
                  {
                    path: "position/:id",
                    element: <PositionPage/>,
                  },
                  {
                    path: "position/new",
                    element: <PositionCreationPage />,
                  },
                  {
                    path: "candidates/:positionId",
                    element: <CandidateCarouselLayout/>,
                    children: [
                      {
                        index: true,
                        element: <Box>....</Box>
                      },
                      {
                        path: ":positionMatchId",
                        element: <CandidateCardPreview />
                      },
                      {
                        path: ":positionMatchId/schedule",
                        element: <CandidateCardSchedule />
                      },
                      {
                        path: ":positionMatchId/reschedule/:meetingId",
                        element: <CandidateCardReschedule />
                      }
                    ]
                  },
/*                  {
                    path: "old-candidates/:positionId/:positionMatchId?",
                    element: <CandidatesPage/>
                  },
                  {
                    path: "old-candidates/:positionId/:positionMatchId/schedule",
                    element: <CandidatesPage schedule={true}/>
                  },*/
                  {
                    path: "contracts",
                    element: <CompanyContracts/>
                  },
                  {
                    path: "hire/:positionId/:positionMatchId",
                    element: <HirePage/>
                  },
                  {
                    path: "interviews/:positionId?",
                    element: <CompanyMeetings/>
                  },
                  {
                    path: "notifications",
                    element: <Notifications/>
                  },
                  {
                    path: "hiring-complete",
                    element: <HiringComplete/>
                  },
                  {
                    path: "thank-you",
                    element: <ThankYou/>
                  },
                  {
                    path: "reschedule/:positionMatchId/meeting/:meetingId",
                    element: <ReschedulePage />
                  }
                ]
              }
            ]
          },
        ]
      }
    ]
  },

  {
    path: "/",
    element: <NotFound/>
  }

], {
  future: {
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
  }
});

import {useParams} from "react-router-dom";
import {
  useProcessSalesAssessmentMutation,
  useSalesAssessmentByContractorSuspenseQuery
} from "../../api/sdl";
import {Box, Button, Stack, Typography} from "@mui/material";
import ScoreGrid from "./ui/ScoreGrid";
import TranscribedAnswerList from "./ui/TranscribedAnswerList";

export function AdminSalesAssessmentReport() {

  const {contractorId} = useParams()
  const {data, refetch}  = useSalesAssessmentByContractorSuspenseQuery({variables: {contractorId}})
  const [requestProcessing] = useProcessSalesAssessmentMutation({variables: {contractorId}})

  const handleUpdateButton = async () => {

    await requestProcessing()
    await refetch()
  }

  return <>
    <Stack spacing={2}>
      <Stack direction={"row"}>
        <Button onClick={() => {alert("Implement me")}}>Transcript old videos</Button>
        <Button onClick={handleUpdateButton}>Update Report</Button>
      </Stack>

      <Box>
        <Typography variant={"body1"}>
          {data?.contractorProfileById?.profile?.firstName} {data?.contractorProfileById?.profile?.lastName} ({data?.contractorProfileById.profile.email})
        </Typography>
      </Box>

      <Box>
        {!!data?.salesAssessmentByContractor?.assessmentCollection &&
            <ScoreGrid data={data?.salesAssessmentByContractor?.assessmentCollection} />
        }
      </Box>
{/*      <Box>
        {!!data?.salesAssessmentByContractor?.assessmentCollection &&
            <SalesAssessmentSummary assessments={data.salesAssessmentByContractor.assessmentCollection} />
        }
      </Box>*/}
      <Box>
        <TranscribedAnswerList answers={data!!.compositeAnswers} scoring={data?.salesAssessmentByContractor?.assessmentCollection} />
      </Box>
    </Stack>
  </>
}


import React, {useCallback, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Box, Button, CircularProgress, Dialog, DialogContent, Typography} from "@mui/material";
import {CrossIconButton} from "../../cross-icon-button";

export interface VideoRecordModalProps {
  open: boolean,
  loading: boolean,
  onClose: () => void,
  title: string,
  onRecorded: (data: Blob) => void,
  videoQuestionId?: string,
  url?: string
}

export function VideoRecordModal(props: VideoRecordModalProps) {
  const [cameraStream, setCameraStream] = React.useState<any>();
  const [recording, setRecording] = React.useState(false);
  const [blob, setBlob] = React.useState<Blob>();
  const [mediaRecorder, setMediaRecorder] = React.useState<any>();
  const [showCamera, setShowCamera] = useState(true)
  const [url, setUrl] = useState<string>()
  const [countdown, setCountdown] = useState(0);
  const [countdownInterval, setCountdownInterval] = useState<any>()

  useEffect(() => {
    setUrl(props.url)
  }, [props.url]);

  const startRecording = () => {
    let options: any;
    if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      options = { mimeType: 'video/webm;codecs=h264' }; //Chromium based browsers will use this mimeType.
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = { mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"', videoBitsPerSecond: 2500000 }; //Safari uses this mimeType.
    }

    const media_recorder = new MediaRecorder(cameraStream, options);
    const blobs_recorded: any[] = [];
    setBlob(undefined);

    media_recorder.addEventListener('dataavailable', function(e) {
      blobs_recorded.push(e.data);
    });

    media_recorder.addEventListener('stop', function() {
      // setBlob(new Blob(blobs_recorded, { type: 'video/mp4' }));
      setBlob(new Blob(blobs_recorded, { type: options.mimeType }));
    });


    setMediaRecorder(media_recorder)

    setCountdown(3)
    setCountdownInterval(setInterval(() => {
      setCountdown(prev => {
        const val = prev - 1;

        if (val <= 0) {
          if (media_recorder.state !== 'recording') {
            media_recorder.start(1000);
          }
          if (countdownInterval) {
            clearInterval(countdownInterval);
          }
        }

        return val;
      })
    }, 1000))

  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setCountdown(0)
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    }
  };

  const onRecordClick = () => {
    setRecording(prev => {
      const newVal = !prev;
      if (newVal) {
        if (!showCamera) {
          setCameraStreamToVideo()
        }
        startRecording();
      } else {
        stopRecording()
        setUrl(undefined);
      }
      return newVal;
    });
  };

  const setCameraStreamToVideo = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true}).catch((err) => {
        enqueueSnackbar(`Camera can't be used. Error message - ${err.message}`, {variant: 'error'});

        props.onClose()
        console.log(err)
      })
      let video = document.querySelector("#video");
      (video as any).srcObject = stream;
      setCameraStream(stream)
      setShowCamera(true)
    } catch(e) {
      console.error(e)
    }
  }, []);

  const setUrlToVideo = useCallback(async () => {
    if (url) {
      setShowCamera(false)
      let video = document.querySelector("#video-play");
      if (video) {
        (video as any).srcObject = null;
        (video as any).src = url;
        (video as any).load();
      }
    }
  }, [url]);

  useEffect(() => {
    if (blob) {
      cleanUpVideoResources()
      props.onRecorded(blob)
    }
  }, [blob]);

  React.useEffect(() => {
    if (props.open) {
      setBlob(undefined);
      setTimeout(() => {
        setCameraStreamToVideo()
      }, 300)
    } else if(recording) {
      stopRecording();
    }
    if (!props.open) {
      setBlob(undefined);
      setRecording(false)
    }
  }, [props.open]);

  const cleanUpVideoResources = () => {
    cameraStream.getTracks().forEach((t: MediaStreamTrack) => t.stop());
    setCameraStream(null)

    let video = document.querySelector("#video");
    if (video) {
      (video as any).srcObject = null;
    }
  }

  const handleModalClose = () => {
    cleanUpVideoResources();
    props.onClose();
  }

  // sx={{'& .MuiDialog-paper': {maxWidth: 'min-content'}}}
  return <Dialog open={props.open} onClose={handleModalClose} maxWidth={'xl'}>
    <DialogContent
      sx={{
        p: 0,
        position: 'relative',
        width: {xs: '100%', md: '600px'},
      }}
    >
      {props.loading && <Box sx={{
        zIndex: 3,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgba(255,255,255,0.8)'
      }}>
          <CircularProgress size={25}/>
      </Box>}
      {countdown > 0 && <Box sx={{
        zIndex: 2,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
          <Typography variant={'h1'} sx={{fontSize: 70, color: '#fff'}}>{countdown}</Typography>
      </Box>}
      <Box sx={{display: 'flex', gap: 2, alignItems: 'flex-start', justifyContent: 'space-between'}}>
        <Typography  sx={{textAlign: 'center', p: '10px'}}><b>{props.title}</b></Typography>
        <CrossIconButton onClose={handleModalClose} />
      </Box>
      <Box component={'video'} sx={{
        display: showCamera ? 'block' : 'none',
        width: {xs: 1, md: 1},
        height: {xs: 1, md: 'inherit'},
        borderRadius: '0 0 4px 4px'
      }} id="video" muted={!!showCamera} autoPlay={true}></Box>

      <Box component={'video'} sx={{
        display: showCamera ? 'none' : 'block',
        width: {xs: 1, md: 1},
        height: {xs: 1, md: 'inherit'},
        borderRadius: '0 0 4px 4px'
      }} id="video-play" muted={!!showCamera} autoPlay={true}></Box>

      <Box sx={{position: 'absolute', width: 1, px: 2, zIndex: 2, gap: 2, bottom: 16, display: 'flex', justifyContent: 'space-between'}}>
        <Button size={'large'} fullWidth variant={'contained'} onClick={onRecordClick} disabled={props.loading} color={recording ? 'primary' : 'error'}>
          {!recording ? 'Record' : 'Stop'}
        </Button>
        {/*        {(!props.loading && url && !recording) && (
          <Button size={'large'} fullWidth variant={'contained'} startIcon={<Play size={24} color="#fff" weight="fill" />}
                  onClick={async () => {
                    await setUrlToVideo();
                  }}>
            Play
          </Button>
        )}*/}
      </Box>
    </DialogContent>
  </Dialog>
}